<template>
  <div>
    <v-container fluid class="py-6">
      <v-row>
        <v-col lg="12" md="12" cols="12">
          <v-card class="card-shadow border-radius-xl h-100">
            <div class="px-4 pt-4" v-if="helpRequest.requester">
              <div class="d-flex align-center">
                <v-row>
                  <v-col cols="6">
                    <div>
                      <h6 class="text-h6 text-typo font-weight-bold mb-2">
                        {{ 'Request Details'|trans }}
                      </h6>
                      <p class="text-sm text-body mb-0">
                        {{ 'Requested on'|trans }} <b>{{ helpRequest.createdAt }}</b>
                      </p>
                    </div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2">
                    <v-btn>{{ helpRequest.status | helpRequestStatus }}</v-btn>
                  </v-col>
                </v-row>
              </div>
              <hr class="horizontal dark mt-0 mb-6" />
              <v-row>
                <v-col cols="6">
                  <v-list>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Full Name'|trans }}:</strong>
                          &nbsp; {{ helpRequest.requester.name }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Mobile'|trans }}:</strong>
                          &nbsp; {{ helpRequest.requester.phone }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Email'|trans }}:</strong>
                          &nbsp; {{ helpRequest.requester.email }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Current location'|trans }}:</strong>
                          &nbsp; {{ helpRequest.currentCity }}
                          {{ helpRequest.currentCountry }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Destination'|trans }}:</strong>
                          &nbsp; {{ helpRequest.destinationCity }}
                          {{ helpRequest.destinationCountry }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Services'|trans }}:</strong>
                          &nbsp; {{ helpRequest.servicesList.join(",") }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Number of people'|trans }}:</strong>
                          &nbsp; {{ helpRequest.numberOfPeople }}
                          <span v-if="helpRequest.numberOfPeople === 1">{{'adult'|trans}}</span>
                          <span v-else>{{'adults'|trans}}</span>
                          + {{ helpRequest.numberOfChildren }}
                          <span v-if="helpRequest.numberOfChildren === 1">{{'child'|trans}}</span>
                          <span v-else>{{'children'|trans}}</span>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Additional info'|trans }}:</strong>
                          &nbsp; {{ helpRequest.additionalInfo }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="6">
                  <v-list>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Reviewer'|trans }}:</strong>
                          &nbsp;
                          {{
                            helpRequest.reviewer
                              ? helpRequest.reviewer.name
                              : "-"
                          }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <label
                            ><strong class="text-dark">{{ 'Assigned to'|trans }}:</strong>
                            &nbsp;{{
                              helpRequest.assignee
                                ? helpRequest.assignee.name
                                : "-"
                            }}
                          </label>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :ripple="false" class="px-0 border-radius-sm">
                      <v-list-item-content class="py-0">
                        <div class="ms-4 text-body text-sm">
                          <strong class="text-dark">{{ 'Closed by'|trans }}:</strong>
                          &nbsp;
                          {{
                            helpRequest.resolver
                              ? helpRequest.resolver.name
                              : "-"
                          }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="3" md="6" class="mx-auto">
          <help-request-status-tracker
            v-if="helpRequest.guid"
            :help-request="helpRequest"
          ></help-request-status-tracker>
        </v-col>
        <v-col lg="9" md="6" class="mx-auto">
          <help-request-messages
            v-if="helpRequest.guid"
            :help-request-guid="helpRequest.guid"
          ></help-request-messages>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HelpRequestStatusTracker from "@/views/Backoffice/Requests/Widgets/HelpRequestStatusTracker";
import HelpRequestMessages from "@/views/Backoffice/Requests/Widgets/HelpRequestMessages";

export default {
  components: {
    HelpRequestMessages,
    HelpRequestStatusTracker,
  },
  data() {
    return {
      helpRequest: {
        requester: null,
        reviewer: null,
        assignee: null,
        resolver: null,
        guid: "",
        additionalInfo: "",
        services: [],
        servicesList: [],
        currentCountry: "",
        currentCity: "",
        destinationCity: "",
        numberOfPeople: null,
        numberOfChildren: null,
        status: null,
        createdAt: null,
      },

    };
  },
  mounted() {
    this.getHelpRequest(this.$route.params.guid);
  },
  methods: {
    refreshHelpRequest() {
      this.getHelpRequest(this.helpRequest.guid);
    },

    getHelpRequest(guid) {
      fetch(
        process.env.VUE_APP_API_URL + "/help-requests/" + guid + "/details",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": this.authToken,
            "Accept-Language": this.$store.getters.getLocale,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.$notify({
              group: "global",
              type: "error",
              title: this.$translator.trans("Unable to fetch the help request"),
              text: data.message ? data.message : "",
            });

            return false;
          }

          this.helpRequest = data.helpRequest;
          this.helpRequest.servicesList = this.helpRequest.services.map(
            function (service) {
              return service.name;
            }
          );
        })
        .catch((error) => {
          console.error("Error:", error);

          this.$notify({
            group: "global",
            type: "error",
            title: this.$translator.trans("Unable to fetch the help request"),
            text: error.message ? error.message : "",
          });
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },

  },
};
</script>
